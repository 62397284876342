import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/help.module.scss";
import { Link } from "gatsby-plugin-intl";

import "../assets/css/helpAccordion.scss";

const PoliticaPage = () => (
	<Layout>
		<Seo title="Política de Privacidad" />
		<section className={styles.help}>
			<article className={styles.bgHelp}>
				<div className={styles.helpWrapper}>
					<Link to="/" className="lineLink">
						<u>Volver a la home</u>
					</Link>
				</div>
				<div className={styles.titleWrapper}>
					<h2>Política de Privacidad</h2>
				</div>
			</article>

			<article className={styles.bgText}>
				<div className={styles.text}>
					<p>
						Cuando precisemos obtener información por su parte, siempre le
						solicitaremos que nos la proporcione voluntariamente de forma
						expresa. Los datos recabados a través de los formularios de
						recogida de datos del sitio web u otras vías, serán
						incorporados a un tratamiento de datos de carácter personal
						del cual es responsable <b>EL PROPIETARIO DE LA WEB</b>.
						<br />
						Esta entidad tratará los datos de forma confidencial y
						exclusivamente con la finalidad de ofrecer los servicios
						solicitados, con todas las garantías legales y de seguridad
						que impone el RGPD UE 2016/679 y el Real Decreto 1720/2007, de
						21 de diciembre y la Ley 34/2002, de 11 de julio, de Servicios
						de la Sociedad de la Información y de Comercio Electrónico.
						<br />
						<b>EL PROPIETARIO DE LA WEB</b> no cederá ni compartirá los
						datos con terceros sin su consentimiento expreso.
						<br />
						Asimismo, <b>EL PROPIETARIO DE LA WEB</b> cancelará o
						rectificará los datos cuando resulten inexactos, incompletos o
						hayan dejado de ser necesarios o pertinentes para su
						finalidad, de conformidad con lo previsto en el RGPD UE
						2016/679, de Protección de Datos de Carácter Personal.
					</p>
					<ul>
						<li>
							El usuario podrá revocar el consentimiento prestado y
							ejercer los derechos de acceso, rectificación, supresión,
							limitación, revocación, oposición y portabilidad de sus
							datos dirigiéndose a tal efecto al domicilio siguiente:
							<b>
								BANSAH & HOHOE S.L., Carrer de Pere IV, 51, 2º 3ª, 08018
								Barcelona
							</b>
							, identificándose debidamente e indicando de forma expresa
							el concreto derecho que se quiere ejercer.
							<br />
						</li>
					</ul>
					<p>
						<b>EL PROPIETARIO DE LA WEB</b> adopta los niveles de
						seguridad correspondientes requeridos en el RGPD UE 2016/679 y
						demás normativa aplicable. No obstante, no puede garantizar la
						absoluta invulnerabilidad de los sistemas, por tanto, no asume
						ninguna responsabilidad por los daños y perjuicios derivados
						de alteraciones que terceros puedan causar en los sistemas
						informáticos, documentos electrónicos o ficheros del usuario.
						<br />
						Si opta por abandonar nuestro sitio web a través de enlaces a
						sitios web no pertenecientes a nuestra entidad,{" "}
						<b>EL PROPIETARIO DE LA WEB</b> no se hará responsable de las
						políticas de privacidad de dichos sitios web ni de las cookies
						que éstos puedan almacenar en el ordenador del usuario.
						Nuestra política con respecto al correo electrónico se centra
						en remitir únicamente comunicaciones que usted haya solicitado
						recibir.
						<br />
						Si prefiere no recibir estos mensajes por correo electrónico
						le ofreceremos a través de los mismos la posibilidad de
						ejercer su derecho de cancelación y renuncia a la recepción de
						estos mensajes, en conformidad con lo dispuesto en el Título
						III, artículo 22 de la Ley 34/2002, de Servicios para la
						Sociedad de la Información y de Comercio Electrónico.
					</p>
				</div>
			</article>
		</section>
	</Layout>
);

export default PoliticaPage;
